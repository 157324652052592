<template>
  <div class="Discount_coupon">
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="平台优惠券"
        name="0"
      >
        <div
          v-if="couponList1.length"
          style="height:100%;width:100%;  "
        >

          <div class="coupon">
            <div
              class="coupon-list"
              v-for="(item,index) in couponList1"
              :key="'2-'+index"
            >
              <ul class="item">
                <li class="left">
                  ￥
                  <span>{{item.get_coupon.money}}</span>
                </li>
                <li class="center">
                  <p class="name">{{item.get_coupon.name}}</p>
                  <p>全平台满 {{item.get_coupon.condition}}元可用</p>
            <p>{{new Date(item.get_coupon.use_start_time).format('yyyy-MM-dd HH:mm')}}至{{new Date(item.get_coupon.use_end_time).format('yyyy-MM-dd HH:mm')}}</p>
                </li>
                <li
                  class="right"
                  v-if="item.receive_status==1"
                >
                  <button
                    class="gray-button"
                    :disabled='true'
                  >已使用</button>
                </li>
                <li
                  class="right"
                  v-if="item.receive_status==0"
                >
                  <button
                    class="red-button"
                    @click="use1(item)"
                  >立即使用</button>
                </li>
              </ul>
            </div>
          </div>

        </div>
        <el-empty
          v-else
          description="暂无优惠券"
        ></el-empty>

      </el-tab-pane>
      <el-tab-pane
        label="店铺优惠券"
        name="1"
      >
        <div v-if="couponList2.length">
          <div class="coupon">
            <div
              class="coupon-list"
              v-for="(item,index) in couponList2"
              :key="'2-'+index"
            >
              <ul class="item">
                <li class="left">
                  ￥
                  <span>{{item.get_shop_coupon.money}}</span>
                </li>
                <li class="center">
                  <p class="name">{{item.get_shop_coupon.name}}</p>
                  <p>{{item.get_shop_coupon.shop_name}}店铺满 {{item.get_shop_coupon.condition}}元可用</p>
                  <p>{{new Date(item.get_shop_coupon.use_start_time).format('yyyy-MM-dd HH:mm')}}至
                  {{new Date(item.get_shop_coupon.use_end_time).format('yyyy-MM-dd HH:mm')}}
                  </p>
                </li>
                <li
                  class="right"
                  v-if="item.receive_status==1"
                >
                  <button
                    class="gray-button"
                    :disabled='true'
                  >已使用</button>
                </li>
                <li
                  class="right"
                  v-if="item.receive_status==0"
                >
                  <button
                    class="red-button"
                    @click="use2(item)"
                  >立即使用</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <el-empty
          v-else
          description="暂无优惠券"
        ></el-empty>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>
<script type="text/javascript">
// import this.$config  from '../../../this.$config/this.$config'
export default {
  data() {
    return {
      couponList1: [],
      couponList2: [],
      activeName: "0"
    };
  },
  mounted() {
    this.getCoupon1();
    this.getCoupon2();
  },
  methods: {
    //跳转可用店铺优惠券产品页面
    use1(item) {
      this.$router.push({
        path: "/sortProduct",
        query: {
          money: item.get_coupon.money
        }
      });
    },
    //跳转可用店铺优惠券产品页面
    use2(item) {
      this.$router.push({
        path: "/shop",
        query: {
          id: item.get_shop_coupon.shop_id
        }
      });
    },
    //获取我的优惠券
    getCoupon1() {
      this.$get("home/coupon_receive", {
        member_id: this.$store.state.member.id,
        
      }).then(res => {
        this.couponList1 = res.items.data;
        console.log("couponList1", this.couponList1);
      });
    },
    //获取我的优惠券
    getCoupon2() {
      this.$get("home/shop_coupon_receive", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.couponList2 = res.items.data;
        console.log("couponList2", this.couponList2);
      });
    }
  },
  components: {},
  created() {
    this.getCoupon2();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.coupon::after {
  clear: both;
  display: block;
  content: "";
}
.coupon {
  margin: 0 -1%;
  .coupon-list {
    color: #666;
    width: 48%;
    height: 150px;
    border: 1px solid #ddd;
    float: left;
    margin: 1%;
    border-radius: 10px;
    box-shadow: 1px 1px 2px #aaa;
    padding: 15px;
    .item {
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      display: flex !important;
      background: #fff4f2;

      .left {
        width: 80px;
        @include center;
        span {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .center {
        padding: 20px;
        p {
          width: 100%;
        }
        flex: 1;
        width: 30px;
        @include wrap-between;
        .name {
          font-weight: bold;
          font-size: 12px;
          font-size: 14px;
        }
      }
      .right {
        padding-right: 10px;
        box-sizing: border-box;
        width: 100px;
        @include center;
        .red-button {
          width: 100%;
          height: 30px;
          border-radius: 80px;
          @include center;
          background: red;
          color: #fff;
          border: 0;
        }
        .gray-button {
          width: 100%;
          height: 30px;
          border-radius: 80px;
          @include center;
          background: #aaa;
          color: #fff;
        }
      }
    }
  }
}
</style>

 